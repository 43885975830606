import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ConfirmationDialogComponent, LoadingComponent, TableDataPickerDialogComponent, TreeDataPickerDialogComponent } from './components';
import { CustomSelectorComponent } from './components/custom-selector';
import { InfoPanelTriggerComponent } from './components/info-panel/info-panel-trigger/info-panel-trigger.component';
import { InfoPanelComponent } from './components/info-panel/info-panel.component';
import { InfoTooltipComponent } from './components/info-tooltip/info-tooltip.component';
import { TextLimiterComponent } from './components/text-limiter/text-limiter.component';
import { MaterialModule } from './material.module';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { StrToLocalDatePipe } from './pipes/str-to-local-date.pipe';

const COMPONENTS = [LoadingComponent, ConfirmationDialogComponent, TableDataPickerDialogComponent, TreeDataPickerDialogComponent, CustomSelectorComponent, InfoTooltipComponent, TextLimiterComponent, InfoPanelComponent, InfoPanelTriggerComponent]
const PIPES = [DateAgoPipe, StrToLocalDatePipe]
@NgModule({
  declarations: [COMPONENTS, PIPES],
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [COMPONENTS, PIPES, FormsModule, MaterialModule, NgJsonEditorModule, ReactiveFormsModule],
  providers: [PIPES]
})
export class SharedModule { }
