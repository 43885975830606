import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { LoadingService } from '@app/core/services';
import { CustomDialogResult } from '@app/core/services/dialog.service';
import { ConfiramtionResult } from '@app/shared/components/confirmation-dialog';
import { DateAgoPipe } from '@app/shared/pipes/date-ago.pipe';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { TableDataPickerConfig } from './table-data-piecker-model';

@Component({
  selector: 'app-table-data-picker-dialog',
  templateUrl: './table-data-picker-dialog.component.html',
  styleUrls: ['./table-data-picker-dialog.component.scss']
})
export class TableDataPickerDialogComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  isLoading: boolean;
  selection: SelectionModel<object>;
  destroy$: Subject<void> = new Subject();

  get tableConfig() {
    return this.data.tableConfig
  }

  get detailsColumn() {
    return this.data.detailsColumnName;
  }

  constructor(
    private loadingService: LoadingService,
    private dialogRef: MatDialogRef<TableDataPickerDialogComponent, CustomDialogResult<object[]>>,
    @Inject(MAT_DIALOG_DATA) public data: TableDataPickerConfig<object[]>,
    private dateAgoPipe: DateAgoPipe
  ) { }

  ngOnInit(): void {
    const isMultipleSelection = !this.data.isSingleSelection;
    this.selection = new SelectionModel<object>(isMultipleSelection, []);
    this.loadingService.loading$.pipe(takeUntil(this.destroy$)).subscribe(x => this.isLoading = x);
    this.tableConfig.setSort(this.sort);
    this.tableConfig.load(this.loadData).pipe(takeUntil(this.destroy$)).subscribe();
  }

  loadData = () => {
    return this.data.loadData();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.tableConfig.dataSource?.data?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.tableConfig.dataSource?.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: object): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row`;
  }

  getViewValue(value: any) {
    if (value instanceof Date) {
      return this.dateAgoPipe.transform(value);
    }
    return value;
  }

  close() {
    this.dialogRef.close({ status: ConfiramtionResult.Cancel, resultData: null });
  }

  get addBtnTooltip() {
    return this.isAddButtonDisabled ? "No items selected" : "";
  }

  get isAddButtonDisabled() {
    return !this.selection?.selected?.length;
  }

  isSelectionDisabled(row: object) {
    return !!this.selection.selected.length &&
      !this.selection.isMultipleSelection() &&
      !this.selection.isSelected(row)
  }

  add() {
    this.dialogRef.close({
      status: ConfiramtionResult.Confirm,
      resultData: this.selection.selected
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}