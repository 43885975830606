<app-header
  [navbarBrandRouterLink]="['/applications']"
  [fixed]="true"
  [navbarBrandFull]="{
    src: 'assets/img/brand/OVSHorizontalv1.png',
    width: 130,
    alt: 'EKS-Ref-Arch Logo'
  }"
  [navbarBrandMinimized]="{
    src: 'assets/img/brand/sygnet.svg',
    width: 30,
    height: 30,
    alt: 'EKS-Ref-Arch Logo'
  }"
  [sidebarToggler]="'lg'"
  [asideMenuToggler]="false"
>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a
        class="nav-link"
        data-toggle="dropdown"
        href="#"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
        (click)="(false)"
      >
        <i class="icon-user icons font-xl image-avatar nav-link"></i>
      </a>
      <div
        class="dropdown-menu dropdown-menu-right"
        *dropdownMenu
        aria-labelledby="simple-dropdown"
      >
        <div class="dropdown-header text-center">
          <strong
            *ngIf="isAuthenticated$ | async as isAuthenticated; else noName"
            >{{ username$ | async }}</strong
          >
          <ng-template #noName>
            <strong>Account</strong>
          </ng-template>
        </div>
        <button class="dropdown-item" (click)="redirectToProfile()">
          <i class="fa fa-user"></i> Profile
        </button>        
        <button
          (click)="logout()"
          *ngIf="isAuthenticated$ | async as isAuthenticated; else noAuth"
          class="dropdown-item"
        >
          <i class="fa fa-lock"></i>Logout
        </button>
        <ng-template #noAuth>
          <button class="dropdown-item" (click)="login()">
            <i class="fa fa-unlock"></i>Login
          </button>
        </ng-template>

      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar
    #appSidebar
    [fixed]="true"
    [display]="'lg'"
    [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)"
  >
    <app-sidebar-nav
      [navItems]="navItems"
      [perfectScrollbar]
      [disabled]="appSidebar.minimized"
    ></app-sidebar-nav>
    <!-- <app-sidebar-minimizer></app-sidebar-minimizer> -->
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <app-info-panel>
      <div class="header">
        <loading class="header__loader"></loading>
        <p></p>
      </div>
      <div class="container-fluid">
        <router-outlet></router-outlet>
      </div>
    </app-info-panel>
    <!-- /.container-fluid -->
  </main>
</div>
<app-footer>
  <span>&copy; {{ currentYear }} Integrationworx Ltd</span>
  <span class="ml-auto"
    >Powered by
    <a href="https://www.integrationworx.com" target="_blank"
      >Integrationworx</a
    ></span
  >
</app-footer>
