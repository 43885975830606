import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { UnauthenticatedLayoutComponent } from './unauthenticated-layout.component';
@NgModule({
  imports: [SharedModule, RouterModule],
  declarations: [UnauthenticatedLayoutComponent],
  exports: []
})
export class UnauthenticatedLayoutModule { }
