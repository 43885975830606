import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageStoreService {
  appSyncUrl: LocalStorageItemManager = generateLocalStorageItemManager('appSyncUrl');
  userPoolId: LocalStorageItemManager = generateLocalStorageItemManager('userPoolId');
  appClientId: LocalStorageItemManager = generateLocalStorageItemManager('appClientId');
  apiGatewayUrl: LocalStorageItemManager = generateLocalStorageItemManager('apiGatewayUrl');

  constructor() { }
}

function generateLocalStorageItemManager(key: string): LocalStorageItemManager {
  return {
    get: () => localStorage.getItem(key),
    clear: () => localStorage.removeItem(key),
    set: (value: string) => localStorage.setItem(key, value)
  }
}

interface LocalStorageItemManager {
  get: () => string;
  set: (value: string) => void;
  clear: () => void;
}