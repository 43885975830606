<div class="dialog">
  <div class="dialog__header">
    <div class="title">
      <h3>{{ title }}</h3>
    </div>
    <div class="close-icon"><mat-icon (click)="closeDialog()">close</mat-icon></div>
  </div>
  <div class="dialog__content">
    <h4>{{ message }}</h4>
  </div>
  <div class="dialog__footer">
    <button mat-button (click)="closeDialog()">Cancel</button>
    <button mat-button (click)="confirm()" cdkFocusInitial>Sure</button>
  </div>
</div>
