import { Component, OnInit } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { Observable } from 'rxjs';
@Component({
  selector: 'loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  isLoading: boolean;
  get loading$(): Observable<boolean> {
    return this.loadingService.loading$;
  }
  constructor(
    private loadingService: LoadingService,
  ) { }

  ngOnInit(): void {
    this.loadingService.loading$.subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }
}