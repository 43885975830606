import { OrderType } from "../models/common.model";

export function generateId() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

export function getArrayWithValidData<T>(array: T[]): T[] {
    return array && array.length ? array.filter(y => !isNullOrUndefined(y)) : []
}


export function isNullOrUndefined(data: any): boolean {
    return data === null || data === undefined;
}

export function sum(nums: number[]): number {
    return getArrayWithValidData(nums).reduce((acc, cur) => {
        return acc + cur
    }, 0)
}

export const deepCopy = <T>(target: T, formatter?: (obj: T) => void): T => {
    if (target === null) {
        return target;
    }
    if (target instanceof Date) {
        return new Date(target.getTime()) as any;
    }
    if (target instanceof Array) {
        const cp = [] as any[];
        (target as any[]).forEach((v) => { cp.push(v); });
        return cp.map((n: any) => deepCopy<any>(n, formatter)) as any;
    }
    if (typeof target === 'object' && target !== {}) {
        const cp = { ...(target as { [key: string]: any }) } as { [key: string]: any };
        Object.keys(cp).forEach(k => {
            cp[k] = deepCopy<any>(cp[k], formatter);
        });
        if (formatter) {
            formatter(cp as T)
        };
        return cp as T;
    }
    return target;
};


export function capitalizeFirstLetterOfTheKeys<T>(obj: T) {
    obj = deepCopy(obj, (o) => {
        for (var key in o) {
            if (o.hasOwnProperty(key)) {
                const newKey = key.charAt(0).toUpperCase() + key.substring(1);
                if (newKey == key) continue;
                o[key.charAt(0).toUpperCase() + key.substring(1)] = o[key];
                delete o[key];
            }
        }
    });
    return obj;
}

export function abstractSort<T>(sortItems: T[], getValue: (sortItem: T) => number | boolean | string | Date, order: OrderType): T[] {
    if (!sortItems || !sortItems.length) return [];

    return sortItems.sort((a, b) => {
        const valA = getValue(a);
        const valB = getValue(b);

        if (valA == valB) return 0;

        if (order == OrderType.Accending) {
            return valA < valB ? -1 : 1;
        }

        if (order == OrderType.Deccending) {
            return valA > valB ? -1 : 1;
        }
    });
}

export function generateSetFromStringArray(array: string[]): Set<string> {
    if (!array || !array.length) return new Set();
    return array.reduce((acc, cur) => {
        if (cur && !acc.has(cur)) {
            acc.add(cur)
        }
        return acc;
    }, new Set<string>());
}

export function downloadFile(name: string, uri: string) {
    let link = document.createElement('a');
    link.setAttribute('type', 'hidden');
    link.href = uri;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
}
