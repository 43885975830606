/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this
 * software and associated documentation files (the "Software"), to deal in the Software
 * without restriction, including without limitation the rights to use, copy, modify,
 * merge, publish, distribute, sublicense, and/or sell copies of the Software, and to
 * permit persons to whom the Software is furnished to do so.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
 * INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
 * PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION
 * OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
 * SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */

import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthConfigurationService } from '@core/auth/auth-configuration.service';
import { AuthModule, OidcConfigService } from 'angular-auth-oidc-client';
// Import 3rd party components
import { AlertModule } from 'ngx-bootstrap/alert';
// RECOMMENDED
import { ToastrModule } from 'ngx-toastr';
import { AppComponent } from './app.component';
// Import routing module
import { AppRoutingModule } from './app.routing.module';
import { httpInterceptorProviders } from './core/api/interceptors';
import { CoreModule } from './core/core.module';
import { TOASTR_CONFIG } from './core/services/notification.service';
import { AuthenticatedLayoutModule, UnauthenticatedLayoutModule } from './layouts';
// Import containers
import { SharedModule } from './shared/shared.module';

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    AlertModule.forRoot(),
    AuthModule.forRoot(),
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    ToastrModule.forRoot(
      TOASTR_CONFIG
    ),
    AuthenticatedLayoutModule,
    UnauthenticatedLayoutModule,
  ],
  declarations: [
    AppComponent
  ],
  providers: [
    HttpClient,
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },
    OidcConfigService,
    httpInterceptorProviders,
    AuthConfigurationService,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
