import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LoadingService, PATHS } from '../services';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(private oidcSecurityService: OidcSecurityService, private router: Router, private loadingService: LoadingService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        return this.loadingService.generateObservableWithLoadingFeature(this.oidcSecurityService.isAuthenticated$.pipe(
            switchMap((isAuthorized: boolean) => {
                if (!isAuthorized) {
                    const path = PATHS.LOGIN.redirectPath();
                    this.router.navigate([path]);
                    return of(false);
                }

                return of(true);
            })
        ))
    }
}
