import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { LoadingService } from '@app/core/services/loading.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, of } from 'rxjs';
import { delay, switchMap } from 'rxjs/operators';
import { RedirectService } from '../services';

@Injectable({
  providedIn: 'root'
})
export class UnAuthGuard {
  constructor(
    private oidcSecurityService: OidcSecurityService,
    private loadingService: LoadingService,
    private redirectService: RedirectService

  ) { } // end constructor

  /**
   * check if route can activate
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.loadingService.generateObservableWithLoadingFeature(of(null).pipe(delay(2000), switchMap(_ => {
      return this.oidcSecurityService.isAuthenticated$.pipe(switchMap(isAuthenticated => {
        if (isAuthenticated) {
          this.redirectService.redirectToHomePage();
          return of(false);
        }
        return of(true);
      }))
    })))
  }
}
