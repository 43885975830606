import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingService, PATHS } from '@app/core/services';
import { navItems } from '@app/_nav';
import { AuthConfigurationService } from '@core/auth/auth-configuration.service';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: "app-dashboard",
  templateUrl: './authenticated-layout.component.html',
  styleUrls: ['./authenticated-layout.component.scss']
})
export class AuthenticatedLayoutComponent implements OnInit, OnDestroy {
  public sidebarMinimized = false;
  public navItems = navItems;
  isAuthenticated$: Observable<Boolean>;
  username$: Observable<string>;

  currentYear: number;

  destroy$: Subject<void> = new Subject();
  constructor(
    public oidcSecurityService: OidcSecurityService,
    private authService: AuthConfigurationService,
    private router: Router,
    private loadingService: LoadingService
  ) { }

  ngOnInit() {
    this.currentYear = new Date().getFullYear();

    this.isAuthenticated$ = this.oidcSecurityService.isAuthenticated$;
    this.username$ = this.oidcSecurityService.userData$.pipe(
      map(ud => ud ? ud['email'] : "")
    );
  }

  login() {
    const path = PATHS.LOGIN.redirectPath();
    this.router.navigate([path]);
  }

  logout() {
    this.oidcSecurityService.logoff();
    this.loadingService.generateObservableWithLoadingFeature(this.authService.LogOutOfCognito()).subscribe();
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }

  redirectToProfile() {
    const path = PATHS.PROFILE.redirectPath();
    this.router.navigate([path]);
  }

  redirectToAuthDebug() {
    const path = PATHS.AUTH.children.INFO.redirectPath();
    this.router.navigate([path]);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
