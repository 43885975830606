import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  currentLoaderInitiators: number = 0
  private _loading$ = new BehaviorSubject<boolean>(false);
  loading$ = this._loading$.asObservable();

  public start() {
    this.currentLoaderInitiators++;
    const isLoading = !!this.currentLoaderInitiators;
    this._loading$.next(isLoading);
  }

  public stop() {
    if (this.currentLoaderInitiators > 0)
      this.currentLoaderInitiators--;

    const isLoading = !!this.currentLoaderInitiators;
    this._loading$.next(isLoading);
  }

  public fullStop() {
    this._loading$.next(false);
  }

  generateObservableWithLoadingFeature<T>(obs: Observable<T>): Observable<T> {
    return of(null).pipe(switchMap(_ => {
      this.start();
      return obs.pipe(finalize(() => this.stop()))
    }))
  }
}
