import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { InfoPanelService } from '@app/core/api/services/info-service.service';
import { generateId } from '@app/core/utils/common.fn';
import { Subject } from 'rxjs';
import { InfoItem } from '../info-panel.component';

@Component({
  selector: 'app-info-panel-trigger',
  templateUrl: './info-panel-trigger.component.html',
  styleUrls: ['./info-panel-trigger.component.scss']
})
export class InfoPanelTriggerComponent implements OnInit, OnDestroy {
  @Input() data: InfoItem;
  tooltip: string;
  triggerId: string;
  destroy$: Subject<void> = new Subject();

  constructor(private infoPanelService: InfoPanelService) { }

  ngOnInit(): void {
    this.triggerId = generateId();
    this.tooltip = "Show details";
  }

  openInfoPanel() {
    this.infoPanelService.openInfoPanel(this.data, this.triggerId);
  }

  ngOnDestroy(): void {
    this.infoPanelService.onTriggerDestroy(this.triggerId);
    this.destroy$.next();
    this.destroy$.complete();
  }

}
