import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateAgo',
  pure: true
})
export class DateAgoPipe implements PipeTransform {
  transform(value: string | Date): any {
    // if no date value, return never 
    if (!value) { return "Never"; }

    const dateToTransform = new Date(value)
    if (dateToTransform) {
      const currentDate = new Date();
      const seconds = getSeconds(currentDate) - getSeconds(dateToTransform);

      if (seconds < 29)
        // less than 30 seconds ago will show as 'Just now'
        return 'Just now';
      const intervals = {
        year: 31536000,
        month: 2592000,
        week: 604800,
        day: 86400,
        hour: 3600,
        minute: 60,
        second: 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
    }
    return dateToTransform;
  }
}

function getSeconds(value: Date) {
  if (!value) return;
  return value.getTime() / 1000;
}
