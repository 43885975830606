<mat-drawer-container>
  <ng-content></ng-content>
  <mat-drawer
    #drawer
    mode="side"
    position="end"
    [opened]="isOpened"
    class="drawer"
  >
    <ng-container *ngIf="data; else noData">
      <div class="header">
        <div class="header__title">
          <div>{{ data.headerTitle }}</div>
        </div>
        <div>
          <button mat-icon-button (click)="close()">
            <mat-icon>close</mat-icon>
          </button>
        </div>
      </div>
      <mat-divider></mat-divider>
      <div class="container">
        <div class="item">
          <div class="content" [innerHtml]="data.paragraph"></div>
          <div class="links" *ngIf="data.links">
            <div class="title links">{{ data.links.title }}</div>
            <div *ngFor="let link of data.links.sources">
              <a target="_blank" [href]="link.src">{{ link.name }}</a>
            </div>
          </div>
        </div>

        <ng-container *ngIf="data.subSections">
          <div class="sub-sections">
            <ng-container *ngFor="let item of data.subSections">
              <div class="item">
                <div class="title">{{ item.headerTitle }}</div>
                <div class="content" [innerHtml]="item.paragraph"></div>
                <div class="links" *ngIf="item.links">
                  <div class="title links">{{ item.links.title }}</div>
                  <div *ngFor="let link of item.links.sources">
                    <a target="_blank" [href]="link.src">{{ link.name }}</a>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </mat-drawer>
</mat-drawer-container>

<ng-template #noData> No Data :( </ng-template>
