import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '@app/shared/shared.module';
import { AppFooterModule, AppHeaderModule, AppSidebarModule } from '@coreui/angular';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { AuthenticatedLayoutComponent } from './authenticated-layout.component';
@NgModule({
  imports: [CommonModule,
    SharedModule,
    RouterModule,

    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot()
  ],
  declarations: [AuthenticatedLayoutComponent],
  exports: [AuthenticatedLayoutComponent]
})
export class AuthenticatedLayoutModule { }
