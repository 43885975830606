import { Injectable } from '@angular/core';
import { InfoItem } from '@app/shared/components/info-panel/info-panel.component';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class InfoPanelService {
  private _toggle: BehaviorSubject<InfoPanelEvent> = new BehaviorSubject({ isOpened: false, data: null });
  infoPanelStateChange: Observable<InfoPanelEvent> = this._toggle.asObservable();
  private _triggerId: string;

  constructor() { }

  openInfoPanel(data: InfoItem = this._toggle.value.data, triggerId: string) {
    this._triggerId = triggerId;
    this._toggle.next({ isOpened: true, data: data });
  };

  closeInfoPanel() {
    this._toggle.next({ isOpened: false, data: this._toggle.value.data });
  };

  toggle() {
    const val = this._toggle.value;
    this._toggle.next({ isOpened: !val.isOpened, data: val.data });
  }

  onTriggerDestroy(triggerId: string) {
    if (triggerId == this._triggerId) {
      this.closeInfoPanel();
    }
  }
}

interface InfoPanelEvent {
  isOpened: boolean;
  data: InfoItem
}