import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/auth/auth.guard';
import { UnAuthGuard } from './core/auth/un-auth.guard';
import { PATHS } from './core/services';
import { AuthenticatedLayoutComponent, UnauthenticatedLayoutComponent } from './layouts';

export const routes: Routes = [
  {
    path: '',
    redirectTo: PATHS.LOGIN.path,
    pathMatch: 'full',
  },
  {
    path: '',
    component: UnauthenticatedLayoutComponent,
    canActivate: [UnAuthGuard],
    children: [
      {
        path: PATHS.LOGIN.path,
        loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule)
      },
      {
        path: PATHS.REGISTRATION.path,
        loadChildren: () => import('./modules/registration/registration.module').then(m => m.RegistrationModule)
      },
    ]
  },
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    data: {
      title: 'Home'
    },
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        redirectTo: PATHS.DASHBOARD.path,
        pathMatch: 'full',
      },
      {
        path: PATHS.AUTH.path,
        loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)
      },
      {
        path: PATHS.DASHBOARD.path,
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: PATHS.ENVIRONMENTS.path,
        loadChildren: () => import('./modules/environments/environments.module').then(m => m.EnvironmentsModule)
      },
      {
        path: PATHS.APPLICATIONS.path,
        loadChildren: () => import('./modules/applications/application.module').then(m => m.ApplicationModule)
      },
      {
        path: PATHS.ACCOUNTS.path,
        loadChildren: () => import('./modules/accounts/accounts.module').then(m => m.AccountsModule)
      },
      {
        path: PATHS.RELEASES.path,
        loadChildren: () => import('./modules/releases/release.module').then(m => m.ReleaseModule)
      },
      {
        path: PATHS.DEPLOYMENTS.path,
        loadChildren: () => import('./modules/deployments/deployment.module').then(m => m.DeploymentModule)
      },
      {
        path: PATHS.USERS.path,
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: PATHS.PROFILE.path,
        loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
      },
    ],
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
