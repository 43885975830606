import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { InfoPanelService } from '@app/core/api/services/info-service.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-info-panel',
  templateUrl: './info-panel.component.html',
  styleUrls: ['./info-panel.component.scss']
})
export class InfoPanelComponent implements OnInit, OnDestroy {
  data: InfoItem;
  isOpened: boolean;

  destroy$: Subject<void> = new Subject();
  constructor(private infoPanelService: InfoPanelService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.infoPanelService.infoPanelStateChange.pipe(takeUntil(this.destroy$)).subscribe(res => {
      if (!res) return;
      this.data = res.data;
      this.isOpened = res.isOpened
    });
  }

  close() {
    this.infoPanelService.toggle();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}


export interface InfoItem {
  headerTitle: string;
  paragraph: string;
  links?: { title: string; sources: { name: string; src: string }[] };
  subSections?: InfoItem[];
}