import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'text-limiter',
  templateUrl: './text-limiter.component.html',
  styleUrls: ['./text-limiter.component.scss']
})
export class TextLimiterComponent implements OnInit {
  @Input() text: string;
  @Input() visibleCharactersCount: number;
  @Input() showTooltip: boolean = true;

  textView: string;
  tooltip: string;
  constructor() { }

  ngOnChanges() {
    this.text = this.text || "";
    this.textView = (this.text || "").substring(0, this.visibleCharactersCount);
    this.tooltip = this.text.length == this.textView.length ? "" : this.text;
    if (this.tooltip) {
      this.textView += "...";
    }
  }
  ngOnInit(): void {
  }
}
