/*
 * Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this
 * software and associated documentation files (the "Software"), to deal in the Software
 * without restriction, including without limitation the rights to use, copy, modify,
 * merge, publish, distribute, sublicense, and/or sell copies of the Software, and to
 * permit persons to whom the Software is furnished to do so.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED,
 * INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
 * PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT
 * HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION
 * OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE
 * SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { LogLevel, OidcConfigService } from 'angular-auth-oidc-client';
import { Observable, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { LocalStorageStoreService } from '../services/local-storage-store.service';
import { PATHS } from '../services/redirect.service';

@Injectable({
  providedIn: 'root'
})
export class AuthConfigurationService {
  params$: Observable<ConfigParams>;
  params: ConfigParams;
  tenantName: string;

  get appSyncUrl() {
    return this.localStorageStore.appSyncUrl.get();
  }


  constructor(private oidcConfigService: OidcConfigService,
    private http: HttpClient,
    private localStorageStore: LocalStorageStoreService) {

  }

  public ConfigureDummy() {

    return () =>
      this.oidcConfigService.withConfig({
        stsServer: '<enter cognito userpool url>',
        redirectUrl: window.location.origin,
        postLogoutRedirectUri: `${window.location.origin}/signout`,
        clientId: '<enter client id>',
        scope: 'openid profile email phone',
        responseType: 'code',
        //silentRenew: true,
        //silentRenewUrl: `${window.location.origin}/silent-renew.html`,
        useRefreshToken: true,
        autoUserinfo: false,
        logLevel: LogLevel.Debug,
      });

  }


  public Configure() {
    const setupAction$ = this.params$.pipe(
      map((customConfig) => {
        return {
          stsServer: customConfig.issuer,
          redirectUrl: customConfig.redirectUri,
          clientId: customConfig.clientId,
          responseType: customConfig.responseType,
          scope: customConfig.scope,
          postLogoutRedirectUri: `${window.location.origin}/signout`,
          startCheckSession: customConfig.start_checksession,
          silentRenew: false, // customConfig.useSilentRefresh,
          silentRenewUrl: customConfig.silentRefreshRedirectUri,
          postLoginRoute: '',
          forbiddenRoute: '/forbidden',
          unauthorizedRoute: PATHS.LOGIN.redirectPath(),
          logLevel: LogLevel.Debug,
          maxIdTokenIatOffsetAllowedInSeconds: 10,
          historyCleanupOff: true,
          useRefreshToken: true,
          autoUserinfo: false,
          cognitoDomain: customConfig.cognitoDomain || 'https://saascoffeekincorqobl.auth.us-east-1.amazoncognito.com',
        };
      }),
      switchMap((config) => this.oidcConfigService.withConfig(config))
    );
    return () => setupAction$.toPromise();

  }

  public LogOutOfCognito(): Observable<any> {
    let appClientId = this.localStorageStore.appClientId.get();
    let userPoolId = this.localStorageStore.userPoolId.get();
    let region = userPoolId.split('_')[0];

    const stsServer = `https://cognito-idp.${region}.amazonaws.com/` + userPoolId;
    const clientId = appClientId;
    const logoutUrl = `${window.location.origin}/`;

    return this.http.get<OpenIdConfigParams>(stsServer + "/.well-known/openid-configuration").pipe(
      tap((openIdConfigParams) => {
        const url = openIdConfigParams.authorization_endpoint.replace("oauth2/authorize", "");
        window.location.href = url + `logout?client_id=${clientId}&logout_uri=${logoutUrl}`;
      })
    )

  }

  public setTenantConfig(tenantName: string): Promise<any> {
    const url = `${environment.regApiGatewayUrl}/tenant/init/` + tenantName;
    this.params$ = this.http.get<ConfigParams>(url);


    const setup$ = this.params$.pipe(
      map((val) => {

        this.localStorageStore.appSyncUrl.set(val.apiUrl);
        this.localStorageStore.userPoolId.set(val.userPoolId);
        this.localStorageStore.appClientId.set(val.appClientId);

        this.setOidcConfig();
        return "success";

      }),
      catchError((error) => {
        return throwError(error);
      })
    );


    return setup$.toPromise();

  }

  public setOidcConfig() {
    let appClientId = this.localStorageStore.appClientId.get();
    let userPoolId = this.localStorageStore.userPoolId.get();

    if (this.isValid(appClientId)
      && this.isValid(userPoolId)) {
      let region = userPoolId.split('_')[0];

      this.oidcConfigService.withConfig({
        stsServer: `https://cognito-idp.${region}.amazonaws.com/${userPoolId}`,
        redirectUrl: `${window.location.origin}/`,
        clientId: appClientId,
        responseType: 'code',
        scope: 'openid profile email',
        postLogoutRedirectUri: `${window.location.origin}/signout`,
        silentRenew: true, // customConfig.useSilentRefresh,
        postLoginRoute: '',
        forbiddenRoute: '/forbidden',
        unauthorizedRoute: PATHS.LOGIN.redirectPath(),
        logLevel: LogLevel.Debug,
        maxIdTokenIatOffsetAllowedInSeconds: 30,
        historyCleanupOff: true,
        useRefreshToken: true,
        autoUserinfo: false,

      });

    }

  }

  private isValid(input: string) {

    return input != null
      && input != ''
      && input != 'undefined';

  }
}

export interface ConfigParams {
  //clearHashAfterLogin: boolean;
  issuer?: string;
  appClientId: string;
  userPoolId: string;
  clientId?: string;
  // nonceStateSeparator: string;
  redirectUri?: string;
  responseType?: string;
  scope?: string;
  sessionChecksEnabled?: boolean;
  // showDebugInformation: boolean;
  silentRefreshRedirectUri?: string;
  // silentRefreshTimeout: number;
  start_checksession?: boolean;
  // strictDiscoveryDocumentValidation: boolean;
  // timeoutFactor: number;
  // useSilentRefresh: boolean;
  cognitoDomain?: string;
  //apiGatewayUrl?: string;
  apiUrl: string;
}

export interface OpenIdConfigParams {
  authorization_endpoint: string;
}
