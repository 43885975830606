<div class="dialog">
  <loading></loading>
  <div class="dialog__header">
    <div class="title">
      <mat-icon>perm_identity</mat-icon>
      <h4>{{ tableConfig.headerTitle }}</h4>
    </div>
    <div class="close-icon"><mat-icon (click)="close()">close</mat-icon></div>
  </div>
  <div class="dialog__content">
    <div class="management">
      <div class="management__filter">
        <mat-form-field>
          <input
            matInput
            [(ngModel)]="tableConfig.filterValue"
            (keyup)="tableConfig.applyFilter($event.target.value)"
            placeholder="Search"
          />
        </mat-form-field>
      </div>
      <div class="management__table">
        <div class="management__table-content">
          <table
            mat-table
            [dataSource]="tableConfig.dataSource"
            matSort
            [matSortActive]="tableConfig.defaultSort?.column"
            [matSortDirection]="tableConfig.defaultSort?.direction"
          >
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  *ngIf="selection.isMultipleSelection()"
                  (change)="$event ? masterToggle() : null"
                  [checked]="selection.hasValue() && isAllSelected()"
                  [indeterminate]="selection.hasValue() && !isAllSelected()"
                  [aria-label]="checkboxLabel()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                  [disabled]="isSelectionDisabled(row)"
                  [aria-label]="checkboxLabel(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <ng-container *ngIf="!!detailsColumn">
              <ng-container [matColumnDef]="detailsColumn">
                <th mat-header-cell *matHeaderCellDef>
                  {{ tableConfig.columnsConfig[detailsColumn].title }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <info-tooltip
                    [tooltip]="
                      getViewValue(
                        element[tableConfig.columnsConfig[detailsColumn].name]
                      )
                    "
                  ></info-tooltip>
                </td>
              </ng-container>
            </ng-container>

            <ng-container *ngFor="let c of tableConfig.displayedColumns">
              <ng-container *ngIf="c != 'select' && c != detailsColumn">
                <ng-container [matColumnDef]="c">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    {{ tableConfig.columnsConfig[c].title }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{
                      getViewValue(element[tableConfig.columnsConfig[c].name])
                    }}
                  </td>
                </ng-container>
              </ng-container>
            </ng-container>
            <tr
              mat-header-row
              *matHeaderRowDef="tableConfig.displayedColumns; sticky: true"
            ></tr>
            <tr
              mat-row
              *matRowDef="let row; columns: tableConfig.displayedColumns"
            ></tr>
          </table>
        </div>

        <div
          class="management__table-info"
          *ngIf="!isLoading && !tableConfig.dataSource?.filteredData?.length"
        >
          <h3 class="color-primary">No Data</h3>
        </div>
      </div>

      <div class="management__actions">
        <div [matTooltip]="addBtnTooltip">
          <button
            [disabled]="isAddButtonDisabled"
            mat-flat-button
            color="primary"
            (click)="add()"
            class="add-btn"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
