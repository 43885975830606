<div class="selector">
  <div class="selector__content">
    <ng-container *ngIf="isMultipleMode; else singleSelectionMode">
      <mat-form-field>
        <mat-label>{{ title }}</mat-label>
        <mat-select
          [formControl]="matSelectControl"
          multiple
          [disabled]="!dataExists || isDisabled"
          (openedChange)="onSelectorOpenChange($event)"
        >
          <div class="filter">
            <div class="filter__actions">
              <div class="filter__actions-filter" *ngIf="hasFilter">
                <div class="filter__actions-search-input">
                  <mat-form-field class="search-value">
                    <input
                      matInput
                      placeholder="Search"
                      (keydown)="$event.stopPropagation()"
                      [(ngModel)]="search"
                      (keyup)="onSearchChange(search)"
                      (paste)="onPaste($event)"
                      autocomplete="off"
                    />
                  </mat-form-field>
                  <button
                    mat-icon-button
                    class="filter__actions-close-btn"
                    *ngIf="search"
                    (click)="resetSearch()"
                    [disabled]="!search"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </div>

              <div
                class="filter__actions-buttons"
                *ngIf="hasSelectAllAndClearAllButtons"
              >
                <div>
                  <button
                    mat-button
                    color="accent"
                    (click)="clearAll()"
                    [disabled]="!canClearAll"
                  >
                    Clear all
                  </button>
                </div>
                <div>
                  <button
                    mat-button
                    color="accent"
                    (click)="selectAll()"
                    [disabled]="!canSelectAll"
                  >
                    Select all
                  </button>
                </div>
              </div>
            </div>
            <div class="filter__items">
              <mat-option
                *ngFor="let option of filteredData"
                [value]="option.value"
                (onSelectionChange)="onSelectionChange($event, option)"
              >
                <ng-container
                  *ngTemplateOutlet="
                    namePainter;
                    context: { $implicit: option }
                  "
                ></ng-container>
              </mat-option>
            </div>
          </div>
        </mat-select>
      </mat-form-field>
    </ng-container>
  </div>
  <div *ngIf="hasResetFeature" class="selector__actions">
    <button
      type="button"
      mat-icon-button
      (click)="reset()"
      [disabled]="isDisabled"
    >
      <mat-icon> close </mat-icon>
    </button>
  </div>
</div>

<ng-template #singleSelectionMode>
  <mat-form-field>
    <mat-label>{{ title }}</mat-label>
    <mat-select
      [formControl]="matSelectControl"
      [disabled]="!dataExists || isDisabled"
      (openedChange)="onSelectorOpenChange($event)"
    >
      <div class="filter">
        <div class="filter__actions">
          <div class="filter__actions-filter" *ngIf="hasFilter">
            <div class="filter__actions-search-input">
              <mat-form-field class="search-value">
                <input
                  matInput
                  placeholder="Search"
                  (keydown)="$event.stopPropagation()"
                  [(ngModel)]="search"
                  (keyup)="onSearchChange(search)"
                  (paste)="onPaste($event)"
                  autocomplete="off"
                />
              </mat-form-field>
              <button
                mat-icon-button
                class="filter__actions-close-btn"
                *ngIf="search"
                (click)="resetSearch()"
                [disabled]="!search"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </div>
        </div>
        <div class="filter__items">
          <mat-option
            *ngFor="let option of filteredData"
            [value]="option.value"
            (onSelectionChange)="onSelectionChange($event, option)"
          >
            <ng-container
              *ngTemplateOutlet="namePainter; context: { $implicit: option }"
            >
            </ng-container>
          </mat-option>
        </div>
      </div>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #namePainter let-option>
  {{ option.name }}
  <span *ngIf="showId" class="custom-selector-id">({{ option.value }})</span>
</ng-template>
