export function generateDateFromStringInLocalDateFormat(dateString: string): Date {
    if (!dateString) return null;
    const date = new Date(dateString);
    const utc = new Date(Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
        date.getHours(),
        date.getMinutes(), date.getSeconds()));
    return utc;
}