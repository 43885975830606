import { EnvironmentConfig } from "./environment.model";

export const environment: EnvironmentConfig = {
  production: false,
  regApiGatewayUrl: 'https://zzjoeatnw2.execute-api.us-east-1.amazonaws.com/prod/',
  marketplaceApiGatewayUrl: 'https://gkg2m6o764.execute-api.us-east-1.amazonaws.com/prod/',
  domain: '',
  purchaseUrl: '',
  planChangeUrl: '',
  contactUsUrl: 'mailto:ovs-support@integrationworx.com',
  freeTrialUrl: 'https://aws.amazon.com/marketplace/pp/prodview-kfws6wngrr7e4',
  learnMoreUrl: 'https://www.integrationworx.com/oversight'
};
