import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class RedirectService {
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  public redirectIfIamOnThepath(currentPath: string, nextPath: string) {
    if (this.router.url === currentPath) {
      this.redirectTo(nextPath);
    }
  }

  public getCurrentPath() {
    return this.router.url;
  }

  public goBackIfPathExists() {
    const url = this.activatedRoute.snapshot.queryParams['returnUrl'];
    if (url) this.redirectTo(url);
  }

  public redirectTo(path: string, extras?: NavigationExtras) {
    return this.router.navigate([path], extras);
  }

  public redirectToLoginPage(extras?: NavigationExtras) {
    const path = PATHS.LOGIN.redirectPath();
    return this.router.navigate([path], extras);
  }

  public redirectToHomePage(extras?: NavigationExtras) {
    const path = PATHS.DASHBOARD.redirectPath();
    return this.router.navigate([path], extras);
  }
}

export const PATHS = {
  LOGIN: {
    path: "login",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return `/${this.path}`;
    }
  },
  APPLICATIONS: {
    path: "applications",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return `/${this.path}`;
    },
    children: {
      LIST: {
        path: "list",
        params: {},
        pathWithParams: function () {
          return `${this.path}`
        },
        redirectPath: function (): string {
          return `/${PATHS.APPLICATIONS.path}/${this.path}`;
        }
      },
      CREATE: {
        path: "create",
        params: {},
        pathWithParams: function () {
          return `${this.path}`
        },
        redirectPath: function (): string {
          return `/${PATHS.APPLICATIONS.path}/${this.path}`;
        },
      },
      EDIT_VERSION: {
        path: "edit-version",
        params: {
          applicationId: "appId",
          applicationVersionKey: "verKey"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.applicationId}/:${this.params.applicationVersionKey}`
        },
        redirectPath: function (applicationId: string, applicationVersionKey: string): string {
          return `/${PATHS.APPLICATIONS.path}/${this.path}/${applicationId}/${applicationVersionKey}`;
        },
      },
      VIEW_CONTAINER: {
        path: "view",
        params: {
          applicationId: "appId"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.applicationId}`
        },
        redirectPath: function (applicationId: string): string {
          return `/${PATHS.APPLICATIONS.path}/${this.path}/${applicationId}`;
        },
      },
      VIEW: {
        path: "view",
        params: {
          applicationId: "appId",
          applicationVersionKey: "verKey"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.applicationId}/:${this.params.applicationVersionKey}`
        },
        redirectPath: function (applicationId: string, applicationVersionKey: string): string {
          return `/${PATHS.APPLICATIONS.path}/${this.path}/${applicationId}/${applicationVersionKey}`;
        },
      },
      EDIT_DEFINITION: {
        path: "editdefn",
        params: {
          applicationId: "appId",
          applicationVersionKey: "verKey"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.applicationId}/:${this.params.applicationVersionKey}`
        },
        redirectPath: function (applicationId: string, applicationVersionKey: string): string {
          return `/${PATHS.APPLICATIONS.path}/${this.path}/${applicationId}/${applicationVersionKey}`;
        },
      }
    }
  },
  DASHBOARD: {
    path: "dashboard",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return this.path;
    }
  },
  ENVIRONMENTS: {
    path: "environments",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return this.path;
    },
    children: {
      CREATE: {
        path: "create",
        params: {},
        pathWithParams: function () {
          return `${this.path}`
        },
        redirectPath: function (): string {
          return `/${PATHS.ENVIRONMENTS.path}/${this.path}`;
        },
      },
      LIST: {
        path: "list",
        params: {},
        pathWithParams: function () {
          return `${this.path}`
        },
        redirectPath: function (): string {
          return `/${PATHS.ENVIRONMENTS.path}/${this.path}`;
        },
      },
      EDIT: {
        path: "edit",
        params: {
          environmentKey: "key"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.environmentKey}`
        },
        redirectPath: function (environmentKey: string): string {
          return `/${PATHS.ENVIRONMENTS.path}/${this.path}/${environmentKey}`;
        },
      },
      DATASOURCES: {
        path: "datasources",
        params: {
          environmentKey: "key"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.environmentKey}`
        },
        redirectPath: function (environmentKey: string): string {
          return `/${PATHS.ENVIRONMENTS.path}/${this.path}/${environmentKey}`;
        },
      },
      VIEW: {
        path: "view",
        params: {
          environmentKey: "key"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.environmentKey}`
        },
        redirectPath: function (environmentKey: string): string {
          return `/${PATHS.ENVIRONMENTS.path}/${this.path}/${environmentKey}`;
        },
      },
      MANAGE_TARGETS: {
        path: "manage-targets",
        params: {
          environmentKey: "envKey",
          datasourceKey: "dataSourceKey"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.environmentKey}/:${this.params.datasourceKey}`
        },
        redirectPath: function (environmentKey: string, datasourceKey: string): string {
          return `/${PATHS.ENVIRONMENTS.path}/${this.path}/${environmentKey}/${datasourceKey}`;
        },
      },
    }
  },
  ACCOUNTS: {
    path: "accounts",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return this.path;
    },
    children: {
      LIST: {
        path: "list",
        params: {},
        pathWithParams: function () {
          return `${this.path}`
        },
        redirectPath: function (): string {
          return `/${PATHS.ACCOUNTS.path}/${this.path}`;
        },
      },
      CREATE: {
        path: "create",
        params: {},
        pathWithParams: function () {
          return `${this.path}`
        },
        redirectPath: function (): string {
          return `/${PATHS.ACCOUNTS.path}/${this.path}`;
        },
      },
      VIEW: {
        path: "view",
        params: {
          accountKey: "key"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.accountKey}`
        },
        redirectPath: function (accountKey: string): string {
          return `/${PATHS.ACCOUNTS.path}/${this.path}/${accountKey}`;
        },
      },
      EDIT: {
        path: "edit",
        params: {
          accountKey: "key"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.accountKey}`
        },
        redirectPath: function (accountKey: string): string {
          return `/${PATHS.ACCOUNTS.path}/${this.path}/${accountKey}`;
        },
      },
    }
  },
  RELEASES: {
    path: "releases",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return `/${this.path}`;
    },
    children: {
      CREATE: {
        path: "create",
        params: {
          applicationId: "appId",
          applicationVersionKey: "verKey"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.applicationId}/:${this.params.applicationVersionKey}`
        },
        redirectPath: function (applicationId: string, applicationVersionKey: string): string {
          return `/${PATHS.RELEASES.path}/${this.path}/${applicationId}/${applicationVersionKey}`;
        },
      },
      VIEW: {
        path: "view",
        params: {
          applicationId: "appId",
          applicationVersionKey: "verKey",
          releaseKey: "relKey",
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.applicationId}/:${this.params.applicationVersionKey}/:${this.params.releaseKey}`
        },
        redirectPath: function (applicationId: string, applicationVersionKey: string, releaseKey: string): string {
          return `/${PATHS.RELEASES.path}/${this.path}/${applicationId}/${applicationVersionKey}/${releaseKey}`;
        },
      },
      DEPLOY: {
        path: "deploy",
        params: {
          applicationId: "appId",
          applicationVersionKey: "verKey",
          releaseKey: "relKey",
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.applicationId}/:${this.params.applicationVersionKey}/:${this.params.releaseKey}`
        },
        redirectPath: function (applicationId: string, applicationVersionKey: string, releaseKey: string): string {
          return `/${PATHS.RELEASES.path}/${this.path}/${applicationId}/${applicationVersionKey}/${releaseKey}`;
        },
      },
    }
  },
  DEPLOYMENTS: {
    path: "deployments",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return `/${this.path}`;
    },
    children: {
      VIEW: {
        path: "view",
        params: {
          applicationId: "appId",
          applicationVersionKey: "verKey",
          releaseKey: "relKey",
          deploymentKey: "depKey"
        },
        pathWithParams: function () {
          return `${this.path}/:${this.params.applicationId}/:${this.params.applicationVersionKey}/:${this.params.releaseKey}/:${this.params.deploymentKey}`
        },
        redirectPath: function (applicationId: string, applicationVersionKey: string, releaseKey: string, deploymentKey: string): string {
          return `/${PATHS.DEPLOYMENTS.path}/${this.path}/${applicationId}/${applicationVersionKey}/${releaseKey}/${deploymentKey}`;
        },
      },
    }
  },
  USERS: {
    path: "users",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return `/${this.path}`;
    }
  },
  PROFILE: {
    path: "profile",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return `/${this.path}`;
    }
  },
  AUTH: {
    path: "auth",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return `/${this.path}`;
    },
    children: {
      INFO: {
        path: "info",
        params: {},
        pathWithParams: function () {
          return `${this.path}`
        },
        redirectPath: function (): string {
          return `/${PATHS.AUTH.path}/${this.path}`;
        },
      }
    }
  },
  REGISTRATION: {
    path: "register",
    params: {},
    pathWithParams: function () {
      return `${this.path}`
    },
    redirectPath: function (): string {
      return `/${this.path}`;
    },
    children: []
  }
}