import { SelectionModel } from '@angular/cdk/collections';
import {NestedTreeControl} from '@angular/cdk/tree';
import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatTreeNestedDataSource} from '@angular/material/tree';
import { TreeNode } from '@app/core/models/forms.model';
import { LoadingService } from '@app/core/services';
import { CustomDialogResult } from '@app/core/services/dialog.service';
import { ConfiramtionResult } from '@app/shared/components/confirmation-dialog';
import { Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { TreeDataPickerConfig } from './tree-data-picker-model';



@Component({
  selector: 'app-tree-data-picker-dialog',
  templateUrl: './tree-data-picker-dialog.component.html',
  styleUrls: ['./tree-data-picker-dialog.component.scss'],
})
export class TreeDataPickerDialogComponent implements OnInit{
  isLoading: boolean;
  selection: SelectionModel<object>;
  destroy$: Subject<void> = new Subject();

  get addBtnTooltip() {
    return this.isAddButtonDisabled ? "No items selected" : "";
  }

  get isAddButtonDisabled() {
    return !this.selection?.selected?.length;
  }

  get treeConfig() {
    return this.configWrapper.treeConfig
  }

  get treeDialogueTittle() {
    return this.configWrapper.dialogTittle
  }


  constructor(
    private loadingService: LoadingService,
    private dialogRef: MatDialogRef<TreeDataPickerDialogComponent, CustomDialogResult<object[]>>,
    @Inject(MAT_DIALOG_DATA) public configWrapper: TreeDataPickerConfig<object[]>,
  ) {
  
  }

  ngOnInit(): void {
    const isMultipleSelection = !this.configWrapper.isSingleSelection;
    this.selection = new SelectionModel<object>(isMultipleSelection, []);
    this.loadingService.loading$.pipe(takeUntil(this.destroy$)).subscribe(x => this.isLoading = x);

    //Load Data
    this.treeConfig.load(this.loadData).pipe(takeUntil(this.destroy$)).subscribe();
    //this.configWrapper.loadData().pipe(takeUntil(this.destroy$)).subscribe();
    
  }

  loadData = () => {
    return this.configWrapper.loadData();
  }

  hasChild = (_: number, node: TreeNode) => !!node.Children && node.Children.length > 0;

  close() {
    this.dialogRef.close({ status: ConfiramtionResult.Cancel, resultData: null });
  }

  selectNode(node): void{
    this.selection.toggle(node);
  }

  isSelectionDisabled(row: object) {
    return !!this.selection.selected.length &&
      !this.selection.isMultipleSelection() &&
      !this.selection.isSelected(row)
  }


  add() {
    this.dialogRef.close({
      status: ConfiramtionResult.Confirm,
      resultData: this.selection.selected
    })
  }

}

interface FoodNode {
  name: string;
  children?: FoodNode[];
}