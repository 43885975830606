import { NgModule } from '@angular/core';
import { ApolloLink, InMemoryCache } from '@apollo/client/core';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { AuthConfigurationService } from './auth/auth-configuration.service';

export function createApollo(httpLink: HttpLink, authService: AuthConfigurationService) {
  const uri = authService.appSyncUrl;
  const link = ApolloLink.from([httpLink.create({ uri })]);
  const cache = new InMemoryCache();

  return {
    link,
    cache
  }
}

@NgModule({
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, AuthConfigurationService],
    },
  ],
})
export class GraphQLModule { }
