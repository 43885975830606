import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { OrderType } from '@app/core/models/common.model';
import { abstractSort, getArrayWithValidData } from '@app/core/utils/common.fn';
import { combineLatest, ReplaySubject, Subject } from 'rxjs';
import { debounceTime, takeUntil, tap } from 'rxjs/operators';
import { SelectorData } from './selector.model';

@Component({
  selector: 'sta-custom-selector',
  templateUrl: './custom-selector.component.html',
  styleUrls: ['./custom-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomSelectorComponent),
      multi: true
    }
  ]
})
export class CustomSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  onChange: any = () => { }
  onTouch: any = () => { }
  isDisabled: boolean;

  writeValue(value: any): void {
    this.selectedData$.next(value);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouch = fn
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  @Input() showId: boolean = false;
  @Input() set data(value: SelectorData[]) {
    const isFirstInitializetionWithNoData = !this.isInitializedWithValidData && (!value || !value.length)
    if (isFirstInitializetionWithNoData) { return; }
    this.isInitializedWithValidData = true;
    this.inputData$.next(value)
  };
  @Input() title: string;
  @Input() isMultipleMode: boolean;
  @Input() hasResetFeature: boolean = false;
  @Input() sortView: boolean = true;

  isInitializedWithValidData: boolean = false;
  filterData: SelectorDataView[];
  filteredData: SelectorDataView[];
  search: string;
  canClearAll: boolean;
  canSelectAll: boolean;
  isLoading: boolean;

  private stateChange$: Subject<void> = new Subject();
  private unsubscribe$: Subject<void> = new Subject();
  private selectedData$: ReplaySubject<string[]> = new ReplaySubject(1);
  private inputData$: ReplaySubject<SelectorData[]> = new ReplaySubject(1);

  get dataExists() {
    return this.filterData && this.filterData.length
  }

  get hasSelectAllAndClearAllButtons() {
    if (!this.filterData) return;
    return this.filterData.length > 8;
  }

  get hasFilter() {
    if (!this.filterData) return;
    return this.filterData.length > 8;
  }

  matSelectControl: FormControl;

  constructor() {
    this.matSelectControl = new FormControl();
  }

  ngOnInit() {
    this.stateChange$.pipe(
      takeUntil(this.unsubscribe$),
      debounceTime(0)
    ).subscribe(_ => {
      const resultValue = this.generateResultValue();
      this.onChange(resultValue)
    })

    this.registerDataListener().subscribe()
  }
  registerDataListener() {
    return combineLatest(this.selectedData$, this.inputData$).pipe(
      takeUntil(this.unsubscribe$),
      tap(([selectedData, inputData]) => {
        this.filterData = getArrayWithValidData(inputData).map<SelectorDataView>(x => ({ isSelected: false, name: x.name, value: x.value }));
        this.setSelectorState(selectedData);
        this.setDataToShow(this.filterData);
        this.stateChange$.next();
      })
    )
  }

  setSelectorState(value: any) {
    this.matSelectControl.setValue(value);
    const controlValue = Array.isArray(value) ? value : [value];
    const selectedDataSet: Set<any> = new Set();
    controlValue.forEach(x => {
      if (x) {
        selectedDataSet.add(x);
      }
    })
    if (this.filterData) {
      this.filterData.forEach(x => {
        if (selectedDataSet.has(x.value)) {
          x.isSelected = true;
        }
        else {
          x.isSelected = false;
        }
      })
    }
  }

  filterOptions(filterItems: SelectorDataView[], filterBy: string): SelectorDataView[] {
    if (!filterItems || !filterItems.length) { return []; }
    const items = !filterBy ? filterItems : filterItems.filter(x => {
      if (!x || !x.name) return false;
      return this.contains(x.name, filterBy) || this.contains(x.value, filterBy)
    });
    return items;
  }

  contains(searchIn: string, searchBy: string): boolean {
    if (!searchIn) return false;
    if (!searchBy) return true;
    return searchIn.toLowerCase().indexOf(searchBy.toLowerCase()) > -1;
  }

  updateButtonsState() {
    this.canClearAll = this.existsItemWithIsSelectedValue(true);
    this.canSelectAll = this.existsItemWithIsSelectedValue(false);
  }

  existsItemWithIsSelectedValue(isSelected: boolean): boolean {
    if (!this.filteredData || !this.filteredData.length) return false;
    return !!this.filteredData.find(x => x.isSelected == isSelected);
  }

  onSearchChange(searchTextValue: string) {
    this.search = searchTextValue;
    this.setDataToShow(this.filterOptions(this.filterData, searchTextValue));
  }

  onPaste(event: ClipboardEvent) {
    const clipboardData = event.clipboardData;
    const pastedText = clipboardData ? clipboardData.getData('text') : '';
    if (pastedText) {
      const keyupEvent = new KeyboardEvent('keyup', { 'bubbles': true });
      if (event.currentTarget && event.currentTarget.dispatchEvent) {
        event.currentTarget.dispatchEvent(keyupEvent)
      }
    }
  }

  resetSearch() {
    this.search = '';
    this.setDataToShow(this.filterData);
  }

  setDataToShow(data: SelectorDataView[]) {
    this.filteredData = this.sortViewData(data);
    this.updateButtonsState();
    this.setSelectorState(this.generateResultValue());
  }

  selectAll() {
    this.setFilteredDataIsSelectedValueTo(true);
    this.matSelectControl.setValue(this.filteredData.map(x => x.value));
    this.stateChange$.next();
  }

  clearAll() {
    this.setFilteredDataIsSelectedValueTo(false);
    this.matSelectControl.setValue(null);
    this.stateChange$.next();
  }

  setFilteredDataIsSelectedValueTo(isSelected: boolean) {
    const dataToModify = this.isMultipleMode ? this.filteredData : this.filterData;
    dataToModify.forEach(x => x.isSelected = isSelected);
  }

  onSelectionChange(event: MaterialOptionChange, option: SelectorDataView) {
    if (event.isUserInput) {
      if (this.isMultipleMode) {
        option.isSelected = event.source.selected;
      }
      else {
        if (option.isSelected) return;
        this.clearAll();
        option.isSelected = true;
      }
      this.stateChange$.next();
    }
    this.updateButtonsState();
  }


  generateResultValue(): any[] {
    const result = this.filterData.filter(x => x && x.isSelected).map(x => x.value);
    return this.isMultipleMode ? result : (result.length ? result[0] : null);
  }

  onSelectorOpenChange(isOpened: boolean) {
    if (!isOpened) this.resetSearch();
  }

  sortViewData(data: SelectorDataView[]) {
    const selectedItems = data.filter(x => x.isSelected);
    const notSelectedItems = data.filter(x => !x.isSelected);

    const formatedSelectedDataOrder = this.sortView ? abstractSort(selectedItems, x => x.name.toLocaleLowerCase(), OrderType.Accending) : selectedItems;
    const formatedNotSelectedDataOrder = this.sortView ? abstractSort(notSelectedItems, x => x.name.toLocaleLowerCase(), OrderType.Accending) : notSelectedItems;

    return [...formatedSelectedDataOrder, ...formatedNotSelectedDataOrder];
  }

  reset() {
    this.clearAll();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}



interface SelectorDataView {
  name: string;
  value: any;
  isSelected: boolean;
}

interface MaterialOptionChange {
  isUserInput: boolean;
  source: {
    value: any;
    selected: boolean;
  }
}