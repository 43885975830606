import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private toastr: ToastrService
  ) { } // end constructor

  /** Success **/
  success(title: string = '', message: string = '') {
    return this.toastr.success(message, title);
  }
  error(title: string = '', message: string = '') {
    return this.toastr.error(message, title);
  }
  warning(title: string = '', message: string = '') {
    return this.toastr.warning(message, title);
  }
  info(title: string = '', message: string = '') {
    return this.toastr.info(message, title);
  }
}
export const TOASTR_CONFIG = {
  timeOut: 10000,
  positionClass: 'toast-bottom-right',
  preventDuplicates: true,
  easeTime: 500,
  closeButton: true,
  progressBar: true
}