import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'sta-unauthenticated-layout',
  templateUrl: './unauthenticated-layout.component.html',
  styleUrls: ['./unauthenticated-layout.component.scss']
})
export class UnauthenticatedLayoutComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit() {
  }

}
