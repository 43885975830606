import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfiramtionResult, ConfirmationDialogComponent, ConfirmationDialogData } from '@app/shared/components/confirmation-dialog';
import { take } from 'rxjs/operators';
import { DialogDefaultConfig } from '../configs/dialog.config';



@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog,) { }

  openConfirmDialog(title: string, message: string, callBackOnSuccess: () => void) {
    const dialogData: ConfirmationDialogData = {
      title: title,
      message: message
    };

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      ...DialogDefaultConfig,
      data: dialogData
    });

    dialogRef.afterClosed().pipe(
      take(1)
    ).subscribe((result: ConfiramtionResult) => {
      if (result == ConfiramtionResult.Cancel) return;
      callBackOnSuccess()
    });
  }

  openDialog<C, D, R>(component: ComponentType<C>, data: D, callBackOnSuccess: (data?: R) => void) {
    const dialogRef = this.dialog.open(component, {
      data: data,
      ...DialogDefaultConfig
    });

    dialogRef.afterClosed().pipe(
      take(1)
    ).subscribe((response: CustomDialogResult<R>) => {
      if (response.status == ConfiramtionResult.Cancel) return;
      callBackOnSuccess(response.resultData)
    });
  }
}

export interface CustomDialogResult<R> {
  status: ConfiramtionResult;
  resultData: R;
}