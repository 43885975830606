import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Home',
    url: '/dashboard',
    icon: "icon-home"
  },
  {
    name: 'Applications',
    url: '/applications',
    icon: 'icon-graph',
  },
  {
    name: 'Admin',
    icon: "icon-settings",
    children: [
      {
        name: 'Environments',
        url: '/environments',
        icon: 'icon-tag',
      },
      {
        name: 'Accounts',
        url: '/accounts',
        icon: 'icon-list',
      },
    ]
  }
];
