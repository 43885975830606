import { Pipe, PipeTransform } from '@angular/core';
import { generateDateFromStringInLocalDateFormat } from '@app/core/utils/date.fn';

@Pipe({
  name: 'strTolocalDate',
  pure: true
})
export class StrToLocalDatePipe implements PipeTransform {
  transform(value: string): any {
    return generateDateFromStringInLocalDateFormat(value);
  }
}

