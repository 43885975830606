import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfiramtionResult, ConfirmationDialogData } from './confirmation-dialog.model';

@Component({
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialogData
  ) {}
  get title() {
    return this.data.title;
  }
  get message() {
    return this.data.message;
  }
  ngOnInit() {}
  confirm() {
    this.dialogRef.close(ConfiramtionResult.Confirm);
  }

  closeDialog() {
    this.dialogRef.close(ConfiramtionResult.Cancel);
  }
}
